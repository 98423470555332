import Authenticated from "../Layout/Authenticated";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { api, url as urlLink, axios } from "../axios";
import Pagination from "@mui/material/Pagination";
import { InfinitySpin } from "react-loader-spinner";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import { format, formatDate } from "date-fns";
import {
  MenuItem,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
  TextField,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Dialog from "@mui/material/Dialog";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import DialogTitle from "@mui/material/DialogTitle";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { PlusCircleIcon } from "@heroicons/react/20/solid";
import { isMobile } from "react-device-detect";

const rows = [
  {
    id: 1,
  },
];

const headCells = [
  {
    id: "udyam_id",
    numeric: false,
    disablePadding: false,
    label: "ID",
  },
  {
    id: "type",
    numeric: false,
    disablePadding: false,
    label: "Type",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
  },
  // {
  //   id: "address",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Address",
  //   sortable: false,
  // },
  {
    id: "contact",
    numeric: false,
    disablePadding: false,
    label: "Contact",
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: "Action",
  },
  // {
  //   id: "protein",
  //   numeric: true,
  //   sortable: false,
  //   disablePadding: false,
  //   label: "",
  // },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const user = useSelector((state) => state.user);

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;
  return (
    <Toolbar
      sx={[
        {
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
        },
        numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        },
      ]}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Nutrition
        </Typography>
      )}
      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};
export default function AllUdyamPage({ isOpen, isClose }) {
  const [show, setShow] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [query, setQuery] = useState("");
  const [selectedDistrict, setselectedDistrict] = useState("");
  const [tempData, setTempData] = useState({});
  const [isBusy, setIsbusy] = useState(true);

  const [entities, setEntities] = useState({
    data: [],
  });
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [startDate, setStartDate] = React.useState();
  const [endDate, setEndDate] = React.useState();
  const [open, setOpen] = useState(false);
  const [modal, setModal] = useState(false);
  const [name, setName] = useState("");

  const submit = (e) => {
    e.preventDefault();
    setIsbusy(true);
    setModal(false);
    let form = {
      entity_id: tempData.id,
      remarks: tempData.remarks,
      is_checked: tempData.is_checked,
      requestEditData: tempData,
    };

    if (tempData.submitEdit === true) {
      form.requestEdit = true;
    }
    setIsbusy(true);
    api
      .post("/api/check", form)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {})
      .finally(() => {
        getData(page);
      });
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseModal = () => {
    setModal(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, data) => {
    event.stopPropagation();
    console.log(data);
    if (data.verify_id) {
      if (data.verify?.trashed_unit) {
        const udyam = data.verify.trashed_unit.trashed_udyam;
        udyam.deleteApprovalId = data.id
        setTempData(udyam);
      }
    }
    // setTempData(data);
    setOpen(true);
  };

  const handleChangePage = (event, newPage) => {
    getData(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const openCheckModal = (e, row) => {
    e.stopPropagation();
    setTempData({ ...row, is_checked: 0 });
    setOpen(false);
    setModal(true);
  };

  const district = [
    { id: 1, name: "Aizawl" },
    { id: 2, name: "Mamit" },
    { id: 3, name: "Lunglei" },
    { id: 4, name: "Champhai" },
    { id: 5, name: "Kolasib" },
    { id: 6, name: "Serchhip" },
    { id: 7, name: "Siaha" },
    { id: 8, name: "Lawngtlai" },
    { id: 9, name: "" },
    // More users...
  ];

  const navigate = useNavigate();

  const filteredDistrict =
    query === ""
      ? district
      : district.filter((district) => {
          return district.name.toLowerCase().includes(query.toLowerCase());
        });

  const getData = async (page) => {
    setIsbusy(true);
    const url = new URL(`${urlLink}/api/deleteApproval`);
    url.searchParams.append("is_checked", 1);
    page && url.searchParams.append("page", page);
    startDate && url.searchParams.append("startDate", startDate);
    selectedDistrict &&
      url.searchParams.append("district", selectedDistrict.toLowerCase());
    axios
      .get(url)
      .then((response) => {
        setEntities(response.data);
      })
      .catch((err) => {
        if (err.status === 401) {
          window.alert("You are not authorized to access this page");
          window.location = "/login";
        }
      })
      .finally(() => {
        setIsbusy(false);
      });

    return 0;
  };

  const handleRemoveField = async (field, index) => {
    setTempData({
      ...tempData,
      [field]: tempData[field].filter((_, i) => i !== index),
    });
  };

  const approve = (event,directives,id) => {
    event.stopPropagation();
    const rand = String(Math.round(Math.random() * 10000));



    if(prompt(`Please enter the number ${rand} to confirm`)===rand)
    {
     api.post(`api/deleteApproval/${id}`,{
      _method:"patch",
      is_approved:directives
     })
     .then(res => getData(page))
    }
    else{
      window.alert("You have entered the wrong number")
    }
  }

  const handleAddField = (field) => {
    setTempData({
      ...tempData,
      [field]: tempData[field].concat({}),
    });
    console.log(tempData[field]);
  };
  const createNewUdyam = () => {
    console.log("here");

    setTempData({
      nics: [],
      checks: [],
      bank_detail: {},
      employment_detail: {
        male: 0,
        female: 0,
        other: 0,
      },
      units: [],
    });
    setModal(true);
  };

  useEffect(() => {
    getData(1);
  }, []);

  return (
    <Authenticated>
      <div className="px-4 sm:px-6 lg:px-8 p-3 overflow-hidden relative">
        <div className="fixed right-5 bottom-5">
          <SpeedDial
            ariaLabel="SpeedDial basic example"
            icon={<SpeedDialIcon />}
          >
            <SpeedDialAction
              icon={<CreateNewFolderIcon />}
              tooltipTitle="New"
              onClick={createNewUdyam}
            />
          </SpeedDial>
        </div>
        <Dialog
          maxWidth="lg"
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <div className=" sm:flex sm:flex-row items-center justify-center w-full">
              <div className="text-lg font-semibold">
                Udyam Registration Number :
              </div>
              <div className="text-lg font-light ml-3">{tempData.udyam_id}</div>
            </div>
          </DialogTitle>
          <div className="px-8 xl:px-16 ">
            <div className="mt-3 text-center sm:mt-5">
              <div className="grid grid-cols-1 md:grid-cols-2 mt-10">
                <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                  <div className="font-bold text-left">
                    Type of Enterprise :
                  </div>
                  <div className="font-light ml-3">
                    {tempData.type_of_enterprise}
                  </div>
                </div>
                <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                  <div className="font-bold text-left">
                    Type of Organisation :{" "}
                  </div>
                  <div className="font-light ml-3">
                    {tempData.type_of_organisation}
                  </div>
                </div>
                <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                  <div className="font-bold text-left">Owner Name : </div>
                  <div className="font-light ml-3">{tempData.owner_name}</div>
                </div>
                <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                  <div className="font-bold text-left">
                    Do you have GSTIN :{" "}
                  </div>
                  <div className="font-light ml-3">{tempData.gstin}</div>
                </div>
                <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                  <div className="font-bold text-left">Email Id : </div>
                  <div className="font-light ml-3">{tempData.email}</div>
                </div>
                <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                  <div className="font-bold text-left">Gender : </div>
                  <div className="font-light ml-3">{tempData.gender}</div>
                </div>
                <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                  <div className="font-bold text-left">
                    Date of Incorporation :{" "}
                  </div>
                  <div className="font-light ml-3">
                    {tempData.date_of_incorporation}
                  </div>
                </div>
                <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                  <div className="font-bold text-left">Major Activity : </div>
                  <div className="font-light ml-3">
                    {tempData.major_activity}
                  </div>
                </div>
                <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                  <div className="font-bold text-left">
                    Name of Enterprise :{" "}
                  </div>
                  <div className="font-light ml-3">
                    {tempData.name_of_enterprise}
                  </div>
                </div>
                <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                  <div className="font-bold text-left">PAN : </div>
                  <div className="font-light ml-3">{tempData.pan}</div>
                </div>
                <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                  <div className="font-bold text-left">Mobile Number : </div>
                  <div className="font-light ml-3">{tempData.mobile}</div>
                </div>
                <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                  <div className="font-bold text-left">Social Category : </div>
                  <div className="font-light ml-3">
                    {tempData.social_category}
                  </div>
                </div>
                <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                  <div className="font-bold text-left">
                    Specially Abled(DIVYANG) :
                  </div>
                  <div className="font-light ml-3">
                    {tempData.specially_abled}
                  </div>
                </div>
                <div className="flex flex-row items-center justify-between grid-cols-2 mt-2 px-3">
                  <div className="font-bold text-left">
                    Date of Commencement of Product/Business :
                  </div>
                  <div className="font-light">
                    {tempData.date_of_commencement
                      ? formatDate(
                          new Date(tempData.date_of_commencement),
                          "dd-MM-yyyy"
                        )
                      : null}
                  </div>
                </div>
              </div>
              <div className="mt-2">
                <div className=" my-10">
                  <div className="space-y-12">
                    <div className="border-b border-gray-900/10 pb-12"></div>
                    <div className="border-b border-gray-900/10 pb-12">
                      <h2 className="text-base font-semibold leading-7 text-gray-900">
                        Bank Details
                      </h2>
                      <div className="grid grid-cols-1 md:grid-cols-3 mt-10">
                        <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                          <div className="font-bold text-left">Bank Name :</div>
                          <div className="font-light ml-3">
                            {tempData.bank_detail?.name}
                          </div>
                        </div>
                        <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                          <div className="font-bold text-left">IFS Code : </div>
                          <div className="font-light ml-3">
                            {tempData.bank_detail?.ifsc}
                          </div>
                        </div>
                        <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                          <div className="font-bold text-left">
                            Bank Account Number :{" "}
                          </div>
                          <div className="font-light ml-3">
                            {tempData?.bank_detail?.account_no}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="border-b border-gray-900/10 pb-12">
                      <h2 className="text-base font-semibold leading-7 text-gray-900">
                        Employment Details
                      </h2>
                      <div className="grid grid-cols-1 md:grid-cols-4 mt-10">
                        <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                          <div className="font-bold text-left">Male :</div>
                          <div className="font-light ml-3">
                            {tempData?.employment_detail?.male}
                          </div>
                        </div>
                        <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                          <div className="font-bold text-left">Female : </div>
                          <div className="font-light ml-3">
                            {tempData?.employment_detail?.female}
                          </div>
                        </div>
                        <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                          <div className="font-bold text-left">Others : </div>
                          <div className="font-light ml-3">
                            {tempData?.employment_detail?.other}
                          </div>
                        </div>
                        <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                          <div className="font-bold text-left">Total : </div>
                          <div className="font-light ml-3">
                            {tempData?.employment_detail?.male +
                              tempData?.employment_detail?.female +
                              tempData?.employment_detail?.other}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="border-b border-gray-900/10 pb-12">
                      <h2 className="text-base font-semibold leading-7 text-gray-900">
                        Investment in Plant and Machinery OR Equipment (in Rs.)
                      </h2>
                      {tempData.investments?.length &&
                        tempData.investments.map((investment, index) => {
                          return (
                            <div
                              className="grid grid-cols-1 md:grid-cols-2 mt-10 pl-10 relative"
                              key={investment.id}
                            >
                              <div className="absolute font-bold mt-2">
                                {index + 1}
                              </div>
                              <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                                <div className="font-bold text-left">
                                  Financial Year :
                                </div>
                                <div className="font-light ml-3">
                                  {investment.financial_year}
                                </div>
                              </div>
                              <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                                <div className="font-bold text-left">
                                  Enterprise Type :
                                </div>
                                <div className="font-light ml-3">
                                  {investment.enterprise_type}
                                </div>
                              </div>
                              <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                                <div className="font-bold text-left">
                                  Written Down Value (WDV) :
                                </div>
                                <div className="font-light ml-3">
                                  {investment.wdv}
                                </div>
                              </div>
                              <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                                <div className="font-bold text-left">
                                  Exclusion of cost of Pollution Control,
                                  Research & Development and Industrial Safety
                                  Devices :
                                </div>
                                <div className="font-light ml-3">
                                  {investment.exclusion_of_cost}
                                </div>
                              </div>
                              <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                                <div className="font-bold text-left">
                                  Net Investment in Plant and Machinery OR
                                  Equipment[(A)-(B)] :{" "}
                                </div>
                                <div className="font-light ml-3">
                                  {investment.net_investment}
                                </div>
                              </div>
                              <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                                <div className="font-bold text-left">
                                  Total Turnover (A) :
                                </div>
                                <div className="font-light ml-3">
                                  {investment.total_turnover}
                                </div>
                              </div>
                              <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                                <div className="font-bold text-left">
                                  Export Turnover (B) :
                                </div>
                                <div className="font-light ml-3">
                                  {investment.export_turnover}
                                </div>
                              </div>
                              <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                                <div className="font-bold text-left">
                                  Net Turnover [(A)-(B)] :
                                </div>
                                <div className="font-light ml-3">
                                  {investment.net_turnover}
                                </div>
                              </div>
                              <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                                <div className="font-bold text-left">
                                  Is ITR Filled? :
                                </div>
                                <div className="font-light ml-3">
                                  {investment.is_itr_filled}
                                </div>
                              </div>
                              <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                                <div className="font-bold text-left">
                                  ITR Type :{" "}
                                </div>
                                <div className="font-light ml-3">
                                  {investment.itr_type}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                    <div className="border-b border-gray-900/10 pb-12">
                      <h2 className="text-base font-semibold leading-7 text-gray-900">
                        Unit(s) Details
                      </h2>
                      {tempData?.trashed_units?.map((unit, index) => {
                        return (
                          <div
                            key={unit.id}
                            className="grid grid-cols-1 md:grid-cols-2 mt-10 relative pl-10"
                          >
                            <div className="absolute font-bold mt-2">
                              {index + 1}
                            </div>
                            <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                              <div className="font-bold text-left">
                                Unit Name :
                              </div>
                              <div className="font-light ml-3">
                                {unit?.name}
                              </div>
                            </div>
                            <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                              <div className="font-bold text-left">Flat :</div>
                              <div className="font-light ml-3">{unit.flat}</div>
                            </div>
                            <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                              <div className="font-bold text-left">
                                Building :{" "}
                              </div>
                              <div className="font-light ml-3">
                                {unit?.building}
                              </div>
                            </div>
                            <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                              <div className="font-bold text-left">
                                Village/Town :
                              </div>
                              <div className="font-light ml-3">
                                {unit?.village}
                              </div>
                            </div>
                            <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                              <div className="font-bold text-left">
                                Block :{" "}
                              </div>
                              <div className="font-light ml-3">
                                {unit?.block}
                              </div>
                            </div>
                            <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                              <div className="font-bold text-left">Road : </div>
                              <div className="font-light ml-3">
                                {unit?.road}
                              </div>
                            </div>
                            <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                              <div className="font-bold text-left">City :</div>
                              <div className="font-light ml-3">
                                {unit?.city}
                              </div>
                            </div>
                            <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                              <div className="font-bold text-left">Pin :</div>
                              <div className="font-light ml-3">{unit?.pin}</div>
                            </div>
                            <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                              <div className="font-bold text-left">State :</div>
                              <div className="font-light ml-3">
                                {unit?.state}
                              </div>
                            </div>
                            <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                              <div className="font-bold text-left">
                                District :{" "}
                              </div>
                              <div className="font-light ml-3">
                                {unit?.district[0]
                                  .toUpperCase()
                                  .concat(unit?.district.slice(1))}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="border-b border-gray-900/10 pb-12">
                    <h2 className="text-base font-semibold leading-7 mt-5 text-gray-900">
                      Official address of Enterprise
                    </h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 mt-10">
                      <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                        <div className="font-bold text-left">
                          Flat/Door/Block No. :
                        </div>
                        <div className="font-light ml-3">
                          {tempData?.official_address?.flat}
                        </div>
                      </div>
                      <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                        <div className="font-bold text-left">
                          Village/Town :
                        </div>
                        <div className="font-light ml-3">
                          {tempData?.official_address?.village}
                        </div>
                      </div>
                      <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                        <div className="font-bold text-left">
                          Road/Street/Lane :{" "}
                        </div>
                        <div className="font-light ml-3">
                          {tempData?.official_address?.road}
                        </div>
                      </div>
                      <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                        <div className="font-bold text-left">State :</div>
                        <div className="font-light ml-3">
                          {tempData?.official_address?.state}
                        </div>
                      </div>
                      <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                        <div className="font-bold text-left">Mobile : </div>
                        <div className="font-light ml-3">
                          {tempData?.official_address?.mobile}
                        </div>
                      </div>
                      <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                        <div className="font-bold text-left">
                          Name of Premises/ Building :{" "}
                        </div>
                        <div className="font-light ml-3">
                          {tempData?.official_address?.name}
                        </div>
                      </div>
                      <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                        <div className="font-bold text-left">Block :</div>
                        <div className="font-light ml-3">
                          {tempData?.official_address?.block}
                        </div>
                      </div>
                      <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                        <div className="font-bold text-left">City :</div>
                        <div className="font-light ml-3">
                          {tempData?.official_address?.city}
                        </div>
                      </div>
                      <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                        <div className="font-bold text-left">District :</div>
                        <div className="font-light ml-3">
                          {tempData?.official_address?.district}
                          {" - "}
                          {tempData?.official_address?.pin}
                        </div>
                      </div>
                      <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                        <div className="font-bold text-left">Email : </div>
                        <div className="font-light ml-3">
                          {tempData?.official_address?.email}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="border-b border-gray-900/10 pb-12">
                    <h2 className="text-base font-semibold leading-7 mt-5 text-gray-900">
                      National Industry Classification Code(S)
                    </h2>
                    {tempData.trashed_nics?.map((nic, index) => (
                      <div
                        className="grid grid-cols-1 md:grid-cols-2 mt-10"
                        key={nic.id}
                      >
                        <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                          <div className="font-bold text-left">
                            Nic 2 Digit :
                          </div>
                          <div className="font-light ml-3">
                            {nic["2_digit"]}
                          </div>
                        </div>
                        <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                          <div className="font-bold text-left">
                            Nic 2 Digit Activity :
                          </div>
                          <div className="font-light ml-3">
                            {nic["2_digit_activity"]}
                          </div>
                        </div>
                        <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                          <div className="font-bold text-left">
                            Nic 4 Digit :
                          </div>
                          <div className="font-light ml-3">
                            {nic["4_digit"]}
                          </div>
                        </div>
                        <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                          <div className="font-bold text-left">
                            Nic 4 Digit Activity :
                          </div>
                          <div className="font-light ml-3">
                            {nic["4_digit_activity"]}
                          </div>
                        </div>
                        <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                          <div className="font-bold text-left">
                            Nic 5 Digit :
                          </div>
                          <div className="font-light ml-3">
                            {nic["5_digit"]}
                          </div>
                        </div>
                        <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                          <div className="font-bold text-left">
                            Nic 5 Digit Activity :
                          </div>
                          <div className="font-light ml-3">
                            {nic["5_digit_activity"]}
                          </div>
                        </div>
                        <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                          <div className="font-bold text-left">Activity :</div>
                          <div className="font-light ml-3">
                            {nic["activity"]}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="">
                    <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                      <div className="font-bold text-left">
                        Are you interested to get registered on Government
                        e-Market (GeM) Portal :{" "}
                      </div>
                      <div className="font-light ml-3">
                        {tempData.gem_portal ? "Yes" : "No"}
                      </div>
                    </div>
                    <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                      <div className="font-bold text-left">
                        Are you interested to get registered on TReDS
                        Portals(one or more) :
                      </div>
                      <div className="font-light ml-3">
                        {tempData.treds_portal ? "Yes" : "No"}
                      </div>
                    </div>
                    <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                      <div className="font-bold text-left">
                        Are you interested to get registered on National Career
                        Service(NCS) Portal :
                      </div>
                      <div className="font-light ml-3">
                        {tempData.ncs_portal ? "Yes" : "No"}
                      </div>
                    </div>
                    <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                      <div className="font-bold text-left">DIC :</div>
                      <div className="font-light ml-3">
                        {tempData.dic?.[0].toUpperCase() + tempData.dic?.slice(1)}
                      </div>
                    </div>
                    <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                      <div className="font-bold text-left">MSME-DI :</div>
                      <div className="font-light ml-3">{tempData.msme_di}</div>
                    </div>
                    <div className="flex flex-row items-center justify-between px-3 grid-cols-2 mt-2">
                      <div className="font-bold text-left">
                        Date of Udyam Registration :{" "}
                      </div>
                      <div className="font-light ml-3">
                        {tempData.date_of_registration
                          ? formatDate(
                              new Date(tempData.date_of_registration),
                              "dd-MM-yyyy"
                            )
                          : null}
                      </div>
                    </div>
                  </div>
                  <div className="border-b border-gray-900/10 pb-12">
                    <h2 className="text-base font-semibold leading-7 mt-5 text-gray-900">
                      Helpline Remarks :
                    </h2>
                    <div className="text-sm font-light text-justify">
                      {tempData.checks &&
                        tempData.checks.map((check) => (
                          <div className="mt-5">
                            <div className=" text-sm text-black text-justify">
                              {check.remarks}
                            </div>
                            <div className="text-xs font-extralight text-right text-gray-500">
                              - {check.user.name}
                            </div>
                            <div className=" justify-center items-center w-full">
                              <div className=" mx-5 bg-gray-400 h-0.5 mt-2 " />
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-center w-full mb-5">
            <Button variant="contained" onClick={handleClose} autoFocus>
              Close
            </Button>
          </div>
        </Dialog>

        <div className="">
          <div className="grid md:grid-cols-3 grid-cols-1 gap-4">
            <div className="md:mt-0 mt-10">
              <TextField
                label="Udyam No."
                className="w-full"
                size="small"
                variant="outlined"
              />
            </div>
            <div className="md:mt-0 mt-10">
              <TextField
                label="Udyam No."
                className="w-full"
                size="small"
                variant="outlined"
              />
            </div>
            <TextField
              label="Owner Name"
              className="w-full"
              size="small"
              variant="outlined"
            />
          </div>
          <div className="md:mt-10 mt-10 grid grid-cols-1 gap-4 md:grid-cols-6">
            <div className=" w-full md:col-span-1 col-span-2">
              <TextField
                className="w-full"
                select
                size="small"
                id="demo-simple-select"
                value={selectedDistrict}
                label="District"
                onChange={(e) => setselectedDistrict(e.target.value)}
              >
                {district.map((dist) => (
                  <MenuItem key={dist.id} value={dist.name}>
                    {dist.name}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div className="md:col-span-1 col-span-2">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  slotProps={{ textField: { size: "small" } }}
                  label="From Date"
                  onChange={(newValue) =>
                    setStartDate(format(new Date(newValue.$d), "yyyy-MM-dd"))
                  }
                  className="w-full"
                />
              </LocalizationProvider>
            </div>

            <div className="md:col-span-1 col-span-2">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  slotProps={{ textField: { size: "small" } }}
                  label="To Date"
                  onChange={(newValue) =>
                    setStartDate(format(new Date(newValue.$d), "yyyy-MM-dd"))
                  }
                  className="w-full"
                />
              </LocalizationProvider>
            </div>
            <div className=" col-span-2 grid grid-cols-2  gap-x-2 mx-3">
              <Button variant="contained" size="small">
                Submit
              </Button>
              <Button variant="contained" color="error" size="small">
                Cancel
              </Button>
            </div>
          </div>
        </div>
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            {isBusy ? (
              <div className="flex justify-center">
                <InfinitySpin
                  visible={true}
                  width="200"
                  color="#4fa94d"
                  ariaLabel="infinity-spin-loading"
                />
              </div>
            ) : (
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8 ">
                <div className="max-w-full overflow-hidden">
                  <Paper>
                    <TableContainer>
                      <Table
                        aria-labelledby="tableTitle"
                        size={dense ? "small" : "medium"}
                      >
                        {/* <EnhancedTableHead
                          numSelected={selected.length}
                          order={order}
                          orderBy={orderBy}
                          onSelectAllClick={handleSelectAllClick}
                          onRequestSort={handleRequestSort}
                          rowCount={rows.length}
                        /> */}
                        <TableBody>
                          {entities?.data.map((row, index) => {
                            const isItemSelected = selected.includes(row.id);
                            const labelId = `enhanced-table-checkbox-${index}`;

                            return (
                              <TableRow
                                hover
                                onClick={(event) => handleClick(event, row)}
                                aria-checked={isItemSelected}
                                tabIndex={-1}
                                key={row.id}
                                selected={isItemSelected}
                                className={
                                  isMobile ? "border-2 rounded-md" : ""
                                }
                                sx={{
                                  cursor: "pointer",
                                  display: isMobile ? "grid" : "table-row",
                                }}
                              >
                                <TableCell
                                  component="th"
                                  id={labelId}
                                  scope="row"
                                  padding="left"
                                >
                                  <div>
                                    {row?.verify?.trashed_udyam?.udyam_id ||
                                      row?.verify?.trashed_unit?.trashed_udyam
                                        ?.udyam_id}
                                  </div>
                                  <div>{row?.verify?.trashed_unit?.id}</div>
                                </TableCell>
                                <TableCell align="left">
                                  <div className="">
                                    {row?.verify_id
                                      ? "Verification"
                                      : row?.unit_id
                                        ? "Unit"
                                        : "Udyam"}
                                  </div>
                                </TableCell>
                                <TableCell align="left">
                                  <div>
                                    {
                                      row?.verify?.trashed_unit?.trashed_udyam
                                        ?.owner_name
                                    }
                                  </div>
                                  <div>{row?.verify?.trashed_unit?.name}</div>
                                </TableCell>

                                <TableCell align="left">
                                  {
                                    row?.verify?.trashed_unit?.trashed_udyam
                                      ?.mobile
                                  }
                                </TableCell>
                                <TableCell align="left">
                                  <div className="grid grid-cols-2 gap-x-1">
                                    <Button
                                      variant="contained"
                                      size="small"
                                      className="text-white bg-blue-700 hover:bg-blue-500 w-full rounded-md"
                                      type="button"
                                      onClick={(e)=>approve(e,true,row?.id)}
                                    >
                                      Approve
                                      <span className="sr-only">{row.name}</span>
                                    </Button>
                                    <Button
                                      variant="contained"
                                      size="small"
                                      color="error"
                                      className="text-white  hover:bg-red-500 w-full bg-red-500 h-8 rounded-md "
                                      type="button"
                                      onClick={(e)=>approve(e,false)}
                                    >
                                      Reject
                                      <span className="sr-only">{row.name}</span>
                                    </Button>
                                  </div>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                          {entities?.data.length - entities?.per_page > 0 && (
                            <TableRow
                              style={{
                                height:
                                  (dense ? 33 : 53) *
                                  (entities?.data.length - entities?.per_page),
                              }}
                            >
                              <TableCell colSpan={6} />
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>

                    <div className="relative py-3">
                      <div className="mt-2 w-full absolute md:left-1/2 -translate-x-1/2 flex justify-center z-30">
                        <Pagination
                          count={Math.ceil(entities.total / entities.per_page)}
                          page={entities.current_page}
                          onChange={(e, number) => getData(number)}
                        />
                      </div>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 20]}
                        component="div"
                        count={entities?.total}
                        rowsPerPage={entities?.per_page}
                        page={entities?.current_page - 1}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </div>
                  </Paper>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Authenticated>
  );
}
