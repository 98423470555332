import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { useEffect, useState } from "react";
import {
  APIProvider,
  Map,
  Pin,
  AdvancedMarker,
  ControlPosition,
  MapControl,
} from "@vis.gl/react-google-maps";
import { api, axios, url } from "../axios";
import { Button, Checkbox } from "@mui/material";
import { useParams } from "react-router-dom";
import { isMobile } from "react-device-detect";

export default function VerifyPage() {
  const [is_active, set_is_active] = useState(true);
  const [location, setLocation] = useState({
    lat: 0,
    lng: 0,
  });

  const [apiKey, setApiKey] = useState("");
  const [image, setImage] = useState();
  const [imageGst, setImageGst] = useState();
  const [imageUrl, setImageUrl] = useState();
  const [imageUrlGst, setImageUrlGst] = useState();
  const [isUser, setIsUser] = useState(null);
  const [user, setUser] = useState({
    name: "",
    phone: "",
    email: "",
    password: "",
  });
  const param = useParams();
  const [errors, setErrors] = useState({});

  const verifyCheck = async () => {
    api.get(`/api/verify/${param.id}?getUserLinkage=1`).then((res) => {
      setIsUser(res.data.user_id);
    });
  };
  useEffect(() => {
    verifyCheck();
  }, []);
  const getLocation = async () => {
    await api
      .get("api/google_api_key")
      .then((res) => setApiKey(res.data))
      .catch((err) => {
        if (err.status === 403) {
          window.alert(
            "You are unauthorized to access this page.Please log in as Verification Staff"
          );
          window.location = "/login";
        }
      });

    await navigator.geolocation.getCurrentPosition(
      async (loc) => {
        setLocation({ lat: loc.coords.latitude, lng: loc.coords.longitude });
      },
      (err) => {
        alert(JSON.stringify(err));
      }
    );
  };

  const submit = (e) => {
    e.preventDefault();
    const form = {
      _method: "patch",
      latitude: location.lat,
      longitude: location.lng,
      image: image,
      image_gst:imageGst,
      is_active: is_active,
      status: "completed",
      user,
    };

    console.log(form);

    axios
      .post(`${url}/api/verify/${param.id}`, form, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        window.location = "/verify";
      })
      .catch((err) => {
        console.log(err);
        if (err.response?.data?.errors) {
          setErrors(err.response.data.errors);
        }
        if (
          err?.response?.data?.status === "District Lead Resource not found"
        ) {
          window.alert(
            "This udyam has no district lead resource to forward to."
          );
        }
        if (err?.response?.data?.status === "Not allowed") {
          window.alert(
            "You are not permissible to verify this unit or you have already verified this unit. Pleas navigate back and retry again."
          );
          window.location = "/verify";
        }
      });
  };

  const requestSoftDelete = () => {
    const code = String(Math.round(Math.random(4) * 10000)).padStart(4, "0");
    if (
      prompt(
        "Are you sure you want to delete this udyam? This action cannot be undone. Please enter the code " +
          code +
          " to confirm this action."
      )
    ) {
      api
        .post("api/deleteApproval", {
          verify_id: param.id,
        })
        .then((res) => {
          window.location = "/verify";
        });
    }
  };
  const handleInputChange = (event) => {
    const size = event.target.files[0].size / 10000000;
    if (size > 8) {
      alert("File size should be less than 8 MB");
      return 0;
    }
    setImage(event.target.files[0]);
    setImageUrl(URL.createObjectURL(event.target.files[0]));
  };
  const handleInputChangeGst = (event) => {
    const size = event.target.files[0].size / 10000000;
    if (size > 8) {
      alert("File size should be less than 8 MB");
      return 0;
    }
    setImageGst(event.target.files[0]);
    setImageUrlGst(URL.createObjectURL(event.target.files[0]));
  };
  useEffect(() => {
    getLocation();
  }, []);

  return (
    <div>
      <form onSubmit={submit}>
        <div className="my-10 mx-10">
          <div className="border-b border-gray-900/10 pb-12">
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Verify Udyam
            </h2>
            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              {location.lat && location.lng ? (
                <APIProvider apiKey={apiKey}>
                  <Map
                    onClick={(e) =>
                      setLocation({
                        lng: e.detail.latLng.lng,
                        lat: e.detail.latLng.lat,
                      })
                    }
                    mapId={"40f13a2318c04c92"}
                    defaultZoom={20}
                    gestureHandling={"greedy"}
                    style={{
                      height: "50vh",
                      width: isMobile ? "83vw" : "95vw",
                    }}
                    defaultCenter={{ lat: location.lat, lng: location.lng }}
                  >
                    <MapControl position={ControlPosition.TOP_LEFT}>
                      {/* .. any component here will be added to the
                      control-containers of the google map instance .. */}
                    </MapControl>
                    <AdvancedMarker
                      draggable={true}
                      position={{ lat: location.lat, lng: location.lng }}
                    >
                      <Pin
                        background={"#FBBC04"}
                        glyphColor={"#000"}
                        borderColor={"#000"}
                      />
                    </AdvancedMarker>
                  </Map>
                </APIProvider>
              ) : (
                <h1>Should be busy</h1>
              )}
              <div className="sm:col-span-3"></div>
              <div className="sm:col-span-3">
                <TextField
                  required
                  onChange={(e) =>
                    setLocation({ lng: location.lng, lat: e.target.value })
                  }
                  value={location.lat}
                  id="outlined-basic"
                  size="small"
                  label="GPS latitude"
                  className="w-full"
                  variant="outlined"
                />
              </div>
              <div className="sm:col-span-3">
                <TextField
                  size="small"
                  onChange={(e) =>
                    setLocation({
                      lat: location.lat,
                      lng: parseFloat(e.target.value),
                    })
                  }
                  value={location.lng}
                  id="outlined-basic"
                  label="GPS longitude"
                  className="w-full"
                  variant="outlined"
                />
              </div>
              {!isUser && (
                <div className="sm:col-span-3">
                  <TextField
                    size="small"
                    onChange={(e) =>
                      setUser({ ...user, ...{ name: e.target.value } })
                    }
                    value={user.name}
                    helperText={errors["user.name"]}
                    id="outlined-basic"
                    required
                    label="Name"
                    className="w-full"
                    variant="outlined"
                  />
                </div>
              )}
              {!isUser && (
                <div className="sm:col-span-3">
                  <TextField
                    size="small"
                    onChange={(e) =>
                      setUser({ ...user, ...{ phone: e.target.value } })
                    }
                    value={user.phone}
                    id="outlined-basic"
                    helperText={errors["user.phone"]}
                    required
                    label="Phone"
                    className="w-full"
                    variant="outlined"
                  />
                </div>
              )}
              {!isUser && (
                <div className="sm:col-span-3">
                  <TextField
                    size="small"
                    onChange={(e) =>
                      setUser({ ...user, ...{ email: e.target.value } })
                    }
                    value={user.email}
                    id="outlined-basic"
                    required
                    type="email"
                    helperText={errors["user.email"]}
                    label="Email"
                    className="w-full"
                    variant="outlined"
                  />
                </div>
              )}
              {!isUser && (
                <div className="sm:col-span-3">
                  <TextField
                    size="small"
                    onChange={(e) =>
                      setUser({ ...user, ...{ password: e.target.value } })
                    }
                    value={user.password}
                    id="outlined-basic"
                    required
                    label="Password"
                    className="w-full"
                    variant="outlined"
                  />
                </div>
              )}
              <div className="sm:col-span-3 relative">
                {!imageUrl ? (
                  <Button
                    type="button"
                    variant="outlined"
                    onClick={() => {
                      document.getElementById("file").click();
                    }}
                    className="z-30 w-full"
                  >
                    Upload Image (Image of the Unit)
                  </Button>
                ) : (
                  <Button
                    type="button"
                    variant="outlined"
                    onClick={() => {
                      setImage(null);
                      setImageUrl(null);
                    }}
                    className="z-30 w-full"
                  >
                    Remove Image
                  </Button>
                )}
                <input
                  onChange={handleInputChange}
                  accept="image/*"
                  label="Upload File"
                  id="file"
                  type="file"
                  required
                  className="  absolute left-0 w-1 h-1"
                  variant="outlined"
                />
                {imageUrl && (
                  <div className="mt-10">
                    <img src={imageUrl} />
                  </div>
                )}
              </div>
              <div className="sm:col-span-3 relative">
                {!imageUrlGst ? (
                  <Button
                    type="button"
                    variant="outlined"
                    onClick={() => {
                      document.getElementById("gst_file").click();
                    }}
                    className="z-30 w-full"
                  >
                    Upload Image of GST
                  </Button>
                ) : (
                  <Button
                    type="button"
                    variant="outlined"
                    onClick={() => {
                      setImageGst(null);
                      setImageUrlGst(null);
                    }}
                    className="z-30 w-full"
                  >
                    Remove Image
                  </Button>
                )}
                <input
                  onChange={handleInputChangeGst}
                  accept="image/*"
                  label="Upload File"
                  id="gst_file"
                  type="file"
                  
                  className="  absolute left-0 w-1 h-1"
                  variant="outlined"
                />
                {imageUrlGst && (
                  <div className="mt-10">
                    <img src={imageUrlGst} />
                  </div>
                )}
              </div>
              <div className="sm:col-span-3">
                <div className="mt-2">
                  <span className="">Active for 3 months</span>

                  <Checkbox
                    label="Active"
                    checked={is_active}
                    onChange={(e) => set_is_active(e.target.checked)}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="mt-6 flex items-center justify-end gap-x-6">
            <button
              type="button"
              onClick={() => requestSoftDelete()}
              className="rounded-md w-52 bg-[#710202] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#950202] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Delete
            </button>
            <button
              type="submit"
              className="rounded-md w-52 bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Verify
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
