"use client";

import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Authenticated from "../Layout/Authenticated";
import { useSelector } from "react-redux";
import { api } from "../axios";

export default function EditProfile() {
  const user = useSelector((state) => state.user);
  const [name, setName] = useState(user?.name);
  const [phone, setPhone] = useState(user?.phone);
  const [email, setEmail] = useState(user?.email);
  const [password, setPassword] = useState();
  const [errors, setErrors] = useState({});
  const [passwordConfirmation, setPasswordConfirmation] = useState();

  const submit = (e) => {
    e.preventDefault();
    setErrors({})
  const form ={
      name,
      phone,
      email,
      password,
      password_confirmation: passwordConfirmation,
  }
  api.post("api/profile",form).then(res => console.log(res))
  .catch(err => {
    setErrors(err.response.data.errors)
  })
  };
  useEffect(() => {
    setName(user?.name);
    setPhone(user?.phone);
    setEmail(user?.email);
  }, [user]);
  console.log(user);
  return (
    <Authenticated>
      <div className="isolate bg-white px-6 py-24 sm:py-32 lg:px-8">
        <div
          aria-hidden="true"
          className="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]"
        >
          <div
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
            className="relative left-1/2 -z-10 aspect-[1155/678] w-[36.125rem] max-w-none -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-40rem)] sm:w-[72.1875rem]"
          />
        </div>
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Edit Profile
          </h2>
        </div>
        <form
          onSubmit={(e) => submit(e)}
          className="mx-auto mt-16 max-w-xl sm:mt-20"
        >
          <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
            <div>
              <div className="mt-2.5">
                <TextField
                  className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  id="outlined-basic"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  label="Name"
                  variant="outlined"
                  required
                  error={Boolean(errors.name)}
                  helperText={errors.name}
                />
              </div>
            </div>
            <div>
              <div className="mt-2.5">
                <TextField
                  required
                  className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  id="outlined-basic"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  label="Phone"
                  variant="outlined"
                  error={Boolean(errors.phone)}
                  helperText={errors.phone}
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <div className="mt-2.5">
                <TextField
                  required
                  className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  id="outlined-basic"
                  value={email}
                  onChange={(e) => setEmail(e.target.value) && setErrors({...errors,...{email:null}})}
                  label="Email"
                  variant="outlined"
                  error={Boolean(errors.email)}
                  helperText={errors.email}
                  type="email"
                />
              </div>
            </div>
            <div>
              <div className="mt-2.5">
                <TextField
                  className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  id="outlined-basic"
                  value={password}
                  onChange={(e) => setPassword(e.target.value) && setErrors({...errors,...{password:null}})}
                  label="Password"
                  variant="outlined"
                  type="password"
                  error={Boolean(errors.password)}
                  helperText={errors.password}
                />
              </div>
            </div>
            <div>
              <div className="mt-2.5">
                <TextField
                  className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  required={Boolean(password)}
                  id="outlined-basic"
                  value={passwordConfirmation}
                  onChange={(e) => setPasswordConfirmation(e.target.value)}
                  label="Confrim Password"
                  variant="outlined"
                  type="password"
                />
              </div>
            </div>
          </div>
          <div className="mt-10">
            <button
              type="submit"
              className="block w-full rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </Authenticated>
  );
}
