import React, { useState, useEffect } from "react";
import { api, url } from "../axios";
import { useNavigate } from "react-router-dom";
import { InfinitySpin } from "react-loader-spinner";
import { useNotifications } from "@toolpad/core";
import { TextField } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../redux/userReducer";

export default function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState("");
  const [isBusy, setIsBusy] = useState(true);
  const notifications = useNotifications();
  const [error, setError] = useState({ email: "", password: "" });
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const submit = async (e) => {
    setIsBusy(true);
    e.preventDefault();
    // await api.get("/sanctum/csrf-cookie").then((response) => {
    //   console.log("Cookies set");
    // });
    api
      .post("api/login", { email: email, password: password })
      .then((response) => {
        if (response.data) {
          dispatch(
            setUser({
              name: response.data.name,
              email: response.data.email,
              phone: response.data.phone,
              roles: response.data.roles,
            })
          );
        }
      })
      .catch((err) => {
        console.log(err)
        if (err?.response?.status === 422) {
          window.alert(err?.response?.data?.message);
          if (err.response?.data?.errors) {
            setError(err.response?.data?.errors);
          }
        } else if (err?.response?.status === 403) {
          window.alert("You are forbidden from accessing this portal");
        }
      })
      .finally(() => {
        setIsBusy(false);
      });
  };

  const checkUser = async () => {
    api
      .get("api/user?getme=1")
      .then(async (response) => {
        if (response.data) {
          dispatch(
            setUser({
              name: response.data.name,
              email: response.data.email,
              phone: response.data.phone,
              roles: response.data.roles,
            })
          );
          // navigate("/dashboard");
        }
      })
      .catch((err) => {
        // api.get("/sanctum/csrf-cookie").then((response) => {
        //   console.log("Cookies set");
        // });
      })
      .finally(() => {
        setIsBusy(false);
      });
  };

  useEffect(() => {
    const xsrf = document.cookie.match(
      /^(.*;)?\s*XSRF-TOKEN\s*=\s*[^;]+(.*)?$/
    );


    if (!xsrf ) {
      api.get("/sanctum/csrf-cookie").then((response) => {
        console.log("Cookies set");
      });
      setIsBusy(false)
    } else {
      checkUser();
    }
  }, []);

  useEffect(() => {
    console.log(user);
    if (user?.roles?.map((e) => e.name).includes("verification_staff")) {
      navigate("/verify");
    } else if (user?.name) {
      navigate("/dashboard");
    }
  }, [user]);

  return (
    <>
      {" "}
      {isBusy ? (
        <div className="w-full min-h-screen flex justify-center items-center">
          <InfinitySpin
            visible={true}
            width="200"
            color="#4fa94d"
            ariaLabel="infinity-spin-loading"
          />
        </div>
      ) : (
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <img
              alt="Your Company"
              src="logo.webp"
              className="mx-auto h-40 w-auto"
            />
            <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
              Sign in to your account
            </h2>
          </div>

          {isBusy ? <div>I am busy</div> : ""}

          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <form onSubmit={submit} className="space-y-6">
              <div>
                <div className="mt-2">
                  <TextField
                  size="small"
                    className="w-full"
                    onChange={(e) => setEmail(e.target.value)}
                    label="Email"
                    autoComplete="off"
                    type="email"
                    error={Boolean(error.email)}
                    helperText={error.email}
                  />
                </div>
              </div>

              <div>

                <div className="mt-1 relative">
                  <TextField
                  size="small"
                    required
                    className="w-full"
                    onChange={(e) => setPassword(e.target.value)}
                    label="Password"
                    type={showPassword ? "string" : "password"}
                    error={Boolean(error.password)}
                    helperText={error.password ?? " "}
                  />
                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center ">
                    {showPassword ? (
                      <button
                        type="button"
                        onClick={() => setShowPassword(!showPassword)}
                        className="text-sm text-gray-500 hover:text-gray-600"
                      >
                        <Visibility />
                      </button>
                    ) : (
                      <button
                        type="button"
                        onClick={() => setShowPassword(!showPassword)}
                        className="text-sm text-gray-500 hover:text-gray-600"
                      >
                        <VisibilityOff />
                      </button>
                    )}
                  </div>
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Sign in
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
}
