import Authenticated from "../Layout/Authenticated";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { api, url as urlLink, axios } from "../axios";
import Pagination from "@mui/material/Pagination";
import { InfinitySpin } from "react-loader-spinner";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import { format, formatDate } from "date-fns";
import {
  MenuItem,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
  TextField,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Dialog from "@mui/material/Dialog";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import DialogTitle from "@mui/material/DialogTitle";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { PlusCircleIcon } from "@heroicons/react/20/solid";

const rows = [
  {
    id: 1,
  },
];

const headCells = [
  {
    id: "date",
    numeric: false,
    disablePadding: false,
    label: "Date",
  },
  {
    id: "query_type",
    numeric: false,
    disablePadding: false,
    label: "Type",
  },

  // {
  //   id: "address",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Address",
  //   sortable: false,
  // },
  {
    id: "contact",
    numeric: false,
    disablePadding: false,
    label: "Contact",
  },
  {
    id: "notes",
    numeric: false,
    disablePadding: false,
    label: "Notes",
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: "Action",
  },
  // {
  //   id: "protein",
  //   numeric: true,
  //   sortable: false,
  //   disablePadding: false,
  //   label: "",
  // },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const user = useSelector((state) => state.user);

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;
  return (
    <Toolbar
      sx={[
        {
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
        },
        numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        },
      ]}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Nutrition
        </Typography>
      )}
      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};
export default function AllUdyamPage({ isOpen, isClose }) {
  const [show, setShow] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [query, setQuery] = useState("");
  const [selectedQueryType, setSelectedQueryType] = useState("");
  const [tempData, setTempData] = useState({});
  const [isBusy, setIsbusy] = useState(true);
  const [queryTypes, setQueryTypes] = useState([]);
  const [entities, setEntities] = useState({
    data: [],
  });
  const [isChecked, setIsChecked] = useState(false);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [startDate, setStartDate] = React.useState();
  const [endDate, setEndDate] = React.useState();
  const [open, setOpen] = useState(false);
  const [modal, setModal] = useState(false);
  const [name, setName] = useState("");

  const submit = (e) => {
    e.preventDefault();

    setIsbusy(true);
    api
      .post(`/api/query/${tempData.id}`,{
        ...tempData,
        ...{ _method: "patch" },
      })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {})
      .finally(() => {
        getData(page);
      });
  };

  const handleClose = () => {
    setOpen(false);
  };
  const getTypes = () => {
    api
      .get("/api/getQueryType")
      .then((res) => {
        console.log(res.data)
        setQueryTypes(res.data);
      })
      .catch((err) => {});
  };

  const handleCloseModal = () => {
    setModal(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };



  const handleChangePage = (event, newPage) => {
    getData(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(Number.parseInt(event.target.value, 10));
    setPage(0);
  };
  const openCheckModal = (e, row) => {
    e.stopPropagation();
    setTempData({ ...row,...{is_seen: row.is_seen?true:false } });

    setModal(true);
  };

  const district = [
    { id: 1, name: "Aizawl" },
    { id: 2, name: "Mamit" },
    { id: 3, name: "Lunglei" },
    { id: 4, name: "Champhai" },
    { id: 5, name: "Kolasib" },
    { id: 6, name: "Serchhip" },
    { id: 7, name: "Siaha" },
    { id: 8, name: "Lawngtlai" },
    { id: 9, name: "" },
    // More users...
  ];

  const navigate = useNavigate();

  const filteredDistrict =
    query === ""
      ? district
      : district.filter((district) => {
          return district.name.toLowerCase().includes(query.toLowerCase());
        });

  const getData = async (page) => {
    setIsbusy(true);
    const url = new URL(`${urlLink}/api/query`);
    page && url.searchParams.append("page", page);
    url.searchParams.append("is_checked", isChecked?1:0);
    startDate && url.searchParams.append("startDate", startDate);
    selectedQueryType && url.searchParams.append("query_type_id", selectedQueryType)
    axios
      .get(url)
      .then((response) => {
        setEntities(response.data);
      })
      .catch((err) => {
        if (err.status === 401) {
          window.alert("You are not authorized to access this page");
          window.location = "/login";
        }
      })
      .finally(() => {
        setIsbusy(false);
      });

    return 0;
  };

  const handleRemoveField = async (field, index) => {
    setTempData({
      ...tempData,
      [field]: tempData[field].filter((_, i) => i !== index),
    });
  };

  const handleAddField = (field) => {
    setTempData({
      ...tempData,
      [field]: tempData[field].concat({}),
    });
    console.log(tempData[field]);
  };
  const createNewUdyam = () => {
    console.log("here");

    setTempData({
      nics: [],
      checks: [],
      bank_detail: {},
      employment_detail: {
        male: 0,
        female: 0,
        other: 0,
      },
      units: [],
    });
    setModal(true);
  };

  useEffect(() => {
    getData(1);
    getTypes();
  }, []);

  return (
    <Authenticated>
      <div className="px-4 sm:px-6 lg:px-8 p-3 overflow-hidden relative">
        <div className="fixed right-5 bottom-5">
          <SpeedDial
            ariaLabel="SpeedDial basic example"
            icon={<SpeedDialIcon />}
          >
            <SpeedDialAction
              icon={<CreateNewFolderIcon />}
              tooltipTitle="New"
              onClick={createNewUdyam}
            />
          </SpeedDial>
        </div>

        <Dialog
          maxWidth="lg"
          open={modal}
          onClose={handleCloseModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <div className=" sm:flex sm:flex-row items-center justify-center w-full">
              Query
            </div>
          </DialogTitle>
          <form onSubmit={submit} className="mx-auto mt-16 max-w-xl sm:mt-20 px-10">
        <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
          <div className="sm:col-span-2">
            <label
              htmlFor="phone-number"
              className="block text-sm/6 font-semibold text-gray-900"
            >
              Thlang rawh le
            </label>

            <select
              id="location"
              name="location"
              defaultValue="Handholding"
              className="block w-full rounded-md border-0 px-3.5 py-2 pl-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
              onChange={(e) =>
                setTempData({ ...tempData, ...{ query_type_id: e.target.value } })
              }
            >
              {queryTypes.map((type, index) => (
                <option key={type.id} value={type.id}>
                  {type.name}
                </option>
              ))}
            </select>
          </div>
          <div className="sm:col-span-2">
            <label
              htmlFor="phone-number"
              className="block text-sm/6 font-semibold text-gray-900"
            >
              Phone number
            </label>

            <div className="relative mt-2.5">
              <input
                required
                id="phone-number"
                name="phone-number"
                type="string"
                max={10}
                value={tempData.mobile}
                onChange={(e) => {
                  (e.target.value.length === 0 ||
                    (Number.parseInt(e.target.value) ===
                      Number.parseInt(e.target.value) &&
                      e.target.value.length < 11)) &&
                    setTempData({ ...tempData, ...{ mobile: e.target.value } });
                }}
                autoComplete="tel"
                className="block w-full rounded-md border-0 px-3.5 py-2 pl-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
              />
            </div>
          </div>
          <div className="sm:col-span-2">
            <label
              htmlFor="message"
              className="block text-sm/6 font-semibold text-gray-900"
            >
              Message
            </label>
            <div className="mt-2.5 min-w-80">
              <textarea
                id="message"
                required

                name="message"
                onChange={(e) =>
                  setQuery({ ...tempData, ...{ notes: e.target.value } })
                }
                rows={4}
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                defaultValue={tempData.notes}
              />
            </div>
            <div className="">
            <Checkbox
  checked={tempData.is_seen}
  onChange={(e)=>{
    setTempData({...tempData,...{ is_seen: e.target.checked } })
  }}
  inputProps={{ 'aria-label': 'controlled' }}
/>
            </div>
          </div>
        </div>
        <div className="mt-10">
          <button
            type="submit"
            className="block w-full rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Checked
          </button>
        </div>
      </form>
        </Dialog>
        <div className="">

          <div className="md:mt-10 mt-10 grid grid-cols-1 gap-4 md:grid-cols-6">
            <div className=" w-full md:col-span-1 col-span-2">
              <TextField
                className="w-full"
                select
                size="small"
                id="demo-simple-select"
                value={selectedQueryType}
                label="Query Type"

                onChange={(e) => setSelectedQueryType(e.target.value)}
              >
                {queryTypes.map((dist) => (
                  <MenuItem key={dist.id} value={dist.id}>
                    {dist.name}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div className="md:col-span-1 col-span-2">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  slotProps={{ textField: { size: "small" } }}
                  label="From Date"
                  onChange={(newValue) =>
                    setStartDate(format(new Date(newValue.$d), "yyyy-MM-dd"))
                  }
                  className="w-full"
                />
              </LocalizationProvider>
            </div>

            <div className="md:col-span-1 col-span-2">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  slotProps={{ textField: { size: "small" } }}
                  label="To Date"
                  onChange={(newValue) =>
                    setStartDate(format(new Date(newValue.$d), "yyyy-MM-dd"))
                  }
                  className="w-full"
                />
              </LocalizationProvider>
            </div>
            <div className="rounded-md border-gray-300" style={{borderWidth:"1px"}}>
              <Checkbox value={isChecked} onChange={(e)=>setIsChecked(e.target.checked)}></Checkbox>
              <span>Is Checked</span>
            </div>
            <div className=" col-span-2 grid grid-cols-2  gap-x-2 mx-3">
              <Button onClick={()=>getData(1)} variant="contained" size="small">
                Submit
              </Button>
              <Button variant="contained" color="error" size="small">
                Cancel
              </Button>
            </div>
          </div>
        </div>
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            {isBusy ? (
              <div className="flex justify-center">
                <InfinitySpin
                  visible={true}
                  width="200"
                  color="#4fa94d"
                  ariaLabel="infinity-spin-loading"
                />
              </div>
            ) : (
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8 ">
                <div className="max-w-full overflow-hidden">
                  <Paper>
                    <TableContainer>
                      <Table
                        aria-labelledby="tableTitle"
                        size={dense ? "small" : "medium"}
                      >
                        <EnhancedTableHead
                          numSelected={selected.length}
                          order={order}
                          orderBy={orderBy}
                          onSelectAllClick={handleSelectAllClick}
                          onRequestSort={handleRequestSort}
                          rowCount={rows.length}
                        />
                        <TableBody>
                          {entities?.data.map((row, index) => {
                            const isItemSelected = selected.includes(row.id);
                            const labelId = `enhanced-table-checkbox-${index}`;

                            return (
                              <TableRow
                                hover

                                aria-checked={isItemSelected}
                                tabIndex={-1}
                                key={row.id}
                                selected={isItemSelected}
                                sx={{ cursor: "pointer" }}
                              >
                                <TableCell
                                  component="th"
                                  id={labelId}
                                  scope="row"
                                  padding="left"
                                >
                                  <div className="w-52">{formatDate(new Date(row.created_at),'dd-MM-yyyy hh:mm:ss a')}</div>
                                </TableCell>
                                <TableCell
                                  component="th"
                                  id={labelId}
                                  scope="row"
                                  padding="left"
                                >
                                  {row.query_type?.name}
                                </TableCell>

                                <TableCell align="left">
                                  <a
                                    target="_blank"
                                    rel="noreferrer noopener"
                                    href={`tel:${row?.mobile}`}
                                    className="cursor-pointer hover:text-indigo-600"
                                  >
                                    {row?.mobile}
                                  </a>
                                </TableCell>
                                <TableCell align="left">
                                  <div className=" text-sm text-ellipsis line-clamp-1 overflow-hidden">
                                    {row?.notes}
                                  </div>
                                </TableCell>
                                <TableCell align="left">
                                  <button
                                    className="text-white bg-blue-700 hover:bg-blue-500 w-28 h-8 rounded-md"
                                    type="button"
                                    onClick={(event) =>
                                      openCheckModal(event, row)
                                    }
                                  >
                                    Check{" "}
                                    <span className="sr-only">{row.name}</span>
                                  </button>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>

                    <div className="relative py-3">
                      <div className="mt-2 w-full absolute md:left-1/2 -translate-x-1/2 flex justify-center z-30">
                        <Pagination
                          count={Math.ceil(entities.total / entities.per_page)}
                          page={entities.current_page}
                          onChange={(e, number) => getData(number)}
                        />
                      </div>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 20]}
                        component="div"
                        count={entities?.total}
                        rowsPerPage={entities?.per_page}
                        page={entities?.current_page - 1}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </div>
                  </Paper>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Authenticated>
  );
}
