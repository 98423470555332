

"use client";

import { Fragment, useState } from "react";
import {
  Dialog,
  DialogPanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from "@headlessui/react";
import {
  ArrowDownCircleIcon,
  ArrowPathIcon,
  ArrowUpCircleIcon,
  Bars3Icon,
  EllipsisHorizontalIcon,
  PlusSmallIcon,
} from "@heroicons/react/20/solid";
import { BellIcon, XMarkIcon } from "@heroicons/react/24/outline";
import Authenticated from "../Layout/Authenticated";

const stats = [
  {
    name: "No. Registered Udyam",
    value: "405,091",
  },
  {
    name: "No. of Verified Unit",
    value: "12,787",
  },
  {
    name: "No. of Unverified Unit",
    value: "245,988",
  },
  {
    name: "Total Revenue",
    value: "₹ 30,156",
  },
];
const index = [
  {
    name: "No. Index",
    value: "35,091",
  },
  {
    name: "No. of Verified Unit",
    value: "12,787",
  },
  {
    name: "No. Employee",
    value: "19,009",
  },
  {
    name: "Total Revenue",
    value: "₹ 310,156",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Dashboard() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <>
      <Authenticated>
        <main>
          <div className="font-normal">Udyam Data</div>
          <div className="relative isolate overflow-hidden">
            {/* Stats */}
            <div className="border-b border-b-gray-900/10 lg:border-t lg:border-t-gray-900/5">
              <dl className="mx-auto grid max-w-7xl grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 lg:px-2 xl:px-0">
                {stats.map((stat, statIdx) => (
                  <div
                    key={stat.name}
                    className={classNames(
                      statIdx % 2 === 1
                        ? "sm:border-l"
                        : statIdx === 2
                          ? "lg:border-l"
                          : "",
                      "flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 border-t border-gray-900/5 px-4 py-10 sm:px-6 lg:border-t-0 xl:px-8"
                    )}
                  >
                    <dt className="text-sm/6 font-medium text-gray-500">
                      {stat.name}
                    </dt>
                    <dd
                      className={classNames(
                        stat.changeType === "negative"
                          ? "text-rose-600"
                          : "text-gray-700",
                        "text-xs font-medium"
                      )}
                    >
                      {stat.change}
                    </dd>
                    <dd className="w-full flex-none text-3xl/10 font-medium tracking-tight text-gray-900">
                      {stat.value}
                    </dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
          <div className="font-normal mt-10">Index Data</div>
          <div className="relative isolate overflow-hidden">
            {/* index */}
            <div className="border-b border-b-gray-900/10 lg:border-t lg:border-t-gray-900/5">
              <dl className="mx-auto grid max-w-7xl grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 lg:px-2 xl:px-0">
                {index.map((index, statIdx) => (
                  <div
                    key={index.name}
                    className={classNames(
                      statIdx % 2 === 1
                        ? "sm:border-l"
                        : statIdx === 2
                          ? "lg:border-l"
                          : "",
                      "flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 border-t border-gray-900/5 px-4 py-10 sm:px-6 lg:border-t-0 xl:px-8"
                    )}
                  >
                    <dt className="text-sm/6 font-medium text-gray-500">
                      {index.name}
                    </dt>
                    <dd
                      className={classNames(
                        index.changeType === "negative"
                          ? "text-rose-600"
                          : "text-gray-700",
                        "text-xs font-medium"
                      )}
                    >
                      {index.change}
                    </dd>
                    <dd className="w-full flex-none text-3xl/10 font-medium tracking-tight text-gray-900">
                      {index.value}
                    </dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </main>
      </Authenticated>
    </>
  );
}
